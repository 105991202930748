.hero-hash-circle-container {
  position: absolute;
  right: -6%;
  top: 0;
  width: 57%;
  z-index: 1;
  .hero-hash-circle {
    width: 100%;
    padding-top: 100%;
    background-image: url('/assets/images/welcome/hash-circle-light.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.cards-hash-square-container {
  width: 47%;
  position: absolute;
  bottom: 0%;
  .cards-hash-square {
    width: 100%;
    padding-top: 55%;
    background-image: url('/assets/images/welcome/hash-square-light.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
  }
}
